import classNames from 'classnames/bind';
import Popup from 'reactjs-popup';
import htmlDocx from 'html-docx-js/dist/html-docx';

import styles from './PopupFinally.module.scss';
import Button from '../../../../components/Button';
import { getObjStyleClassName } from '../../../../helpers/';
import { convertExampleToText } from '../../../../helpers';
import images from '../../../../assets/images';
import { routes } from '../../../../config';
const cx = classNames.bind(styles);

const contentStyle = getObjStyleClassName(cx('content-popup'));
const overlayStyle = getObjStyleClassName(cx('overlay'));
const arrowStyle = getObjStyleClassName(cx()); // style for an svg element

function PopupFinally({ listExample, onOpen = () => {}, exampleResult, handleRetry = {} }) {
    const downloadDocx = (content, fileName = 'document.docx') => {
        const converted = htmlDocx.asBlob(content);
        const url = URL.createObjectURL(converted);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    const getRate = (score) => {
        if (score === 10) {
            return { type: 'perfect', title: 'Xuất sắc' };
        } else if (score > 8) {
            return { type: 'good', title: 'Giỏi' };
        } else if (score > 6.5) {
            return { type: 'average', title: 'Khá' };
        } else if (score > 4) {
            return { type: 'poor', title: 'Trung bình' };
        } else {
            return { type: 'bad', title: 'Yếu' };
        }
    };
    return (
        <Popup open modal onOpen={onOpen} onClose={handleRetry} {...{ contentStyle, overlayStyle, arrowStyle }}>
            <div className={cx('wrapper')}>
                <div className={cx('title')}>
                    <h1>KẾT QUẢ LÀM BÀI THI</h1>
                    <h1>{exampleResult.name}</h1>
                </div>
                <div className={cx('content')}>
                    <div className={cx('result')}>
                        <div className={cx('image')}>
                            <div className={cx('title')}>Chúc mừng</div>
                            <img src={images.finallyGif} alt="Chúc mừng"></img>
                            <div>Bạn đã hoàn thành phần thi</div>
                        </div>
                        <div className={cx('info')}>
                            <div className={cx('score')}>{Math.round(exampleResult.score * 10) / 10} điểm</div>
                            <div className={cx('rate')}>
                                Xếp loại:
                                <div className={cx('rate-content', getRate(exampleResult.score).type)}>
                                    {getRate(exampleResult.score).title}
                                </div>
                            </div>
                            <div className={cx('detail')}>
                                <div>
                                    Số câu đúng: {exampleResult.correct}/{exampleResult.num_question}
                                </div>
                                <div>Thời gian làm bài: {exampleResult.time}</div>
                            </div>
                        </div>
                    </div>
                    <div
                        className={cx('word_example')}
                        dangerouslySetInnerHTML={{ __html: convertExampleToText(listExample, '<br>') }}
                    ></div>
                </div>
                <div className={cx('action')}>
                    <Button
                        primary
                        onClick={() => {
                            const content = document.querySelector('.' + cx('word_example')).innerHTML;
                            downloadDocx(content);
                        }}
                    >
                        Tải đề thi
                    </Button>
                    <Button primary>Làm lại câu sai</Button>
                    <Button primary onClick={handleRetry}>
                        Làm lại bài thi
                    </Button>
                    <Button primary to={routes.exam} style={{ background: 'red' }}>
                        Thoát
                    </Button>
                </div>
            </div>
        </Popup>
    );
}

export default PopupFinally;
