import classNames from 'classnames/bind';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { forwardRef, memo, useImperativeHandle, useRef } from 'react';

import styles from './Button.module.scss';
const cx = classNames.bind(styles);

function Button(
    {
        validateInput,
        leftIcon,
        rightIcon,
        className,
        primary,
        outline,
        icon,
        white,
        danger,
        small,
        large,
        iconColor,
        to,
        onClick = () => {},
        href,
        disable,
        userselect,
        children,
        ...props
    },
    ref,
) {
    const classes = cx('wraper', className, {
        primary,
        outline,
        'icon-single': icon,
        white,
        danger,
        small,
        large,
        disable,
        userselect,
    });
    const buttonRef = useRef();
    let Component = 'button';
    let _props = {
        onClick,
    };
    if (disable) {
        _props = {};
    }
    if (to) {
        Component = Link;
        _props.to = to;
    } else if (href) {
        Component = 'a';
        _props.href = href;
    }
    const handleValidateInput = (validateInput) => {
        const tempEvent = _props.onClick;
        _props.onClick = () => {
            const error = validateInput.map((input) => {
                return input.current.validate(true);
            });

            if (error.every((element) => element)) {
                tempEvent();
            }
        };
    };
    if (validateInput) {
        handleValidateInput(validateInput);
    }
    useImperativeHandle(ref, () => ({
        click() {
            buttonRef.current.click();
        },
    }));
    return (
        <Component ref={buttonRef} className={classes} disabled={!!disable} {..._props} {...props}>
            {icon ? (
                <FontAwesomeIcon icon={icon} color={iconColor}></FontAwesomeIcon>
            ) : (
                <>
                    {leftIcon && (
                        <div className={cx('icon')}>
                            <FontAwesomeIcon icon={leftIcon}></FontAwesomeIcon>
                        </div>
                    )}
                    <span className={cx('title')}>{children}</span>
                    {rightIcon && (
                        <div className={cx('icon')}>
                            <FontAwesomeIcon icon={rightIcon}></FontAwesomeIcon>
                        </div>
                    )}
                </>
            )}
        </Component>
    );
}

export default memo(forwardRef(Button));
