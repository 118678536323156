const routes = {
    home: '/',
    login: '/login' + Math.random(),
    register: '/register',
    verifyEmail: '/verify-email',
    exam: '/example',
    editExample(params) {
        return params ? `/myexam/${params}/edit` : '/myexam/:subject/edit';
    },
    test: '/test',
    abc: '/abc',
    conTest(params) {
        return params ? `/exam/${params}` : '/exam/:subject';
    },
    myExam: '/myexam',
    createExample: '/myexam/create-example',
    profile: '/profile',
};
export default routes;
