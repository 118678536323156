import classNames from 'classnames/bind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-regular-svg-icons/faClock';
import 'reactjs-popup/dist/index.css';
import { faCopy, faHeart, faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import {
    faCirclePlay,
    faCircleQuestion,
    faDownload,
    faSquarePollVertical,
    faThumbsUp,
    faTrashCan,
} from '@fortawesome/free-solid-svg-icons';

import styles from './CardExample.module.scss';
import Button from '../Button';
import PopupStart from '../PopupStart';
import images from '../../assets/images';
import { routes } from '../../config';
import { useGlobalContext } from '../../hooks/useGlobalContext';
import request from '../../utils/request';

const cx = classNames.bind(styles);

function CardExample({ getExample = () => {}, className, myExample, example, empty }) {
    const classes = cx('wraper', className, {
        'my-example': myExample,
    });
    const { popupWarning, toastPromise } = useGlobalContext();
    if (empty) {
        return (
            <div className={cx('wraper', 'empty')}>
                <div className={cx('img')}></div>
                <div className={cx('title')}></div>
                <div className={cx('info')}>
                    <div>
                        <div className={cx('avatar')}></div>
                        <div className={cx('name')}></div>
                    </div>
                    <div className={cx('date')}></div>
                </div>
                <div className={cx('line')}></div>
                <div className={cx('line')}></div>
                <div className={cx('button')}></div>
            </div>
        );
    }

    const date = new Date(example.created_at);

    return (
        <div className={classes}>
            <img
                alt="HTML & CSS"
                src={
                    example.user_id === '9'
                        ? 'https://64.media.tumblr.com/61acc444e25323ab7d81b42408d442c9/tumblr_p11jvpNfVx1wn2b96o1_500.gif'
                        : images.defaultCard
                }
            ></img>
            <p className={cx('title')}>{example.name}</p>
            <div className={cx('example-info')}>
                {myExample ?? (
                    <div className={cx('info')}>
                        <img src={example.avatar} alt="Avatar"></img>
                        <p className={cx('name')}>{example.username}</p>
                    </div>
                )}
                <div className={cx('date')}>
                    <FontAwesomeIcon icon={faClock}></FontAwesomeIcon>
                    <p>{`${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`}</p>
                </div>
            </div>
            <div className={cx('statistics')}>
                <div>
                    <FontAwesomeIcon color="#fca218" icon={faCircleQuestion}></FontAwesomeIcon>{' '}
                    <p>{example.num_question}</p>
                </div>
                <div>
                    <FontAwesomeIcon color="#2cb44c" icon={faSquarePollVertical}></FontAwesomeIcon>{' '}
                    <p>{example.count_test}</p>
                </div>
                <div>
                    <FontAwesomeIcon color="rgb(10, 143, 220)" icon={faThumbsUp}></FontAwesomeIcon>{' '}
                    <p>{example.count_like}</p>
                </div>
                <div>
                    <FontAwesomeIcon color="rgb(10, 143, 220)" icon={faDownload}></FontAwesomeIcon> <p>1</p>
                </div>
            </div>
            <div className={cx('tag')}>
                <Button outline className={cx('tag-button')}>
                    {example.credits} tín chỉ
                </Button>
                <Button outline className={cx('tag-button')}>
                    {example.sector}
                </Button>
            </div>
            {myExample && (
                <div className={cx('action-crud')}>
                    <Button
                        to={routes.editExample(example.id)}
                        state={{ a: 1 }}
                        icon={faPenToSquare}
                        iconColor="var(--primary)"
                    ></Button>
                    <Button icon={faCopy} iconColor="var(--text-color)"></Button>
                    <Button
                        onClick={() => {
                            popupWarning({
                                message: 'Bạn có chắc muốn xóa đề thi này không ?',
                                accecpt: () => {
                                    const toastDelete = toastPromise('Đang xóa đề thi...');
                                    request
                                        .delete('/delete-myexample', { params: { id: example.id } })
                                        .then(() => {
                                            toastDelete.success('Xóa thành công !');
                                            getExample();
                                        })
                                        .catch(() => {
                                            toastDelete.error('Xóa thất bại !');
                                        });
                                },
                            });
                        }}
                        icon={faTrashCan}
                        iconColor="#f44c44"
                    ></Button>
                </div>
            )}

            <div className={cx('action')}>
                <PopupStart
                    idExample={example.id}
                    trigger={
                        <Button className={cx('play')} primary small leftIcon={faCirclePlay}>
                            Bắt đầu
                        </Button>
                    }
                ></PopupStart>

                {myExample || (
                    <div>
                        <Button icon={faThumbsUp} className={cx('button')}></Button>
                        <Button icon={faHeart} className={cx('button')}></Button>
                    </div>
                )}
            </div>
        </div>
    );
}

export default CardExample;
